import React from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text, Card } from '@rebass/emotion'


export default () =>
    <StaticQuery
        query={query}
        render={({ allProject: { edges: items } }) => (
            <Box py={50} css={{
                backgroundColor: '#F3F5F6',
            }}>
                <Box m='auto' css={{
                    maxWidth: '1250px',
                    width: '95%',
                    zIndex: 1,
                    position: 'relative'
                }}>
                    <Flex flexWrap='wrap'>
                        {items.filter((i, idx) => idx < 9).map(({ node }) =>
                            <Box key={node.id} width={[1 / 1, 1 / 2, 1 / 3]}>
                                <Card m={10} my={15}
                                    borderRadius={5}>
                                    <Link to={node.path} css={{ textDecoration: 'none' }}>
                                        <Box css={[{ overflow: 'hidden', borderRadius: 5 }]}>
                                            <Box css={{
                                                position: 'relative',
                                                height: 0,
                                                paddingBottom: '65%',
                                                overflow: 'hidden'
                                            }}>
                                                <GatsbyImage image={getImage(node.teaser.file)} alt={node.projekt + " " + node.beschreibung} />
                                            </Box>
                                            <Box py={[20, 40]} px={[20, 30]} css={{
                                                backgroundColor: '#fff'
                                            }}>
                                                <Heading
                                                    mt={10}
                                                    mb={[15, 30]}
                                                    color="#064360"
                                                    fontWeight={400}
                                                    fontSize={[24, 30]}>
                                                    {node.titelLagebeschreibung}
                                                </Heading>
                                                <Text fontSize={16} lineHeight={1.8} color="#064360">
                                                    <div>{node.projekt}</div>
                                                    <div>{node.beschreibung}</div>
                                                </Text>
                                            </Box>
                                        </Box>
                                    </Link>
                                </Card>
                            </Box>
                        )}
                    </Flex>
                    <Box m={20}>
                        <Link my={20} to="/referenzen" css={{ textDecoration: "none", display: 'inline-block' }}>
                            <Text textAlign="left" lineHeight={2} fontWeight={600} fontSize={[16, 16]} color="rgb(18, 50, 80)">Weitere Referenzen<span />&#8594;</Text>
                        </Link>
                    </Box>
                </Box>
            </Box>
        )}
    />

const query = graphql`
{
    allProject(sort: {fields: [konstruktionReihenfolge], order: ASC}) {
      edges {
        node {
          id
          titelLagebeschreibung
          beschreibung
          konstruktionsVariante
          konstruktionReihenfolge
          sonderkonstruktion
          gelander
          beschichtung
          entwasserungebene
          konstruktionsVariante
          fussboden
          projektgrosse
          ort
          slug
          path
          projekt
          teaser {
            file {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
  
`